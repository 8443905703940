<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    :is-loading="isLoading"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <!-- Headers -->
    <template
      slot="table-column"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'checked'">
        <b-form-checkbox
          v-model="selectAll"
          :value="true"
          :unchecked-value="false"
          class="mr-2 vgt-checkbox-col"
        />
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template slot="emptystate">
      <div
        v-if="isLoading"
        class="text-center"
      >
        Fetching data
      </div>
      <div
        v-else
        class="text-center"
      >
        No data for table
      </div>
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field == 'checked' && !props.row.vgtDisabled">
        <b-form-checkbox
          v-model="selected"
          :value="props.row.participantId"
        />
      </span>
      <!-- Column: Action -->
      <div
        v-if="props.column.label === 'Action'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex"
      >
        <template v-if="props.row.status !== formStatusDisplay[formStatus.COMPLETED]">
          <b-button
            v-if="props.row.action.isMaxFormReminders || props.row.completed_at"
            variant="primary"
            size="sm"
            :disabled="true"
          >
            {{ props.row.action.text }}
          </b-button>
          <b-button
            v-else
            variant="primary"
            size="sm"
            @click="sendReminder(props.row.action.userId, props.row.userFormId)"
          >
            {{ props.row.action.text }}
          </b-button>
        </template>
      </div>
      <div
        v-else-if="props.column.field === 'participants'"
      >
        <router-link
          :to="{
            name: 'champion-program-participant',
            params: { id: defaultProgramId, participantId: props.row.participantId },
          }"
          class="table-primary-link"
        >
          {{ props.formattedRow[props.column.field] }}
        </router-link>
      </div>
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        {{ props.formattedRow[props.column.field] }}
      </span>
      <span
        v-else-if="props.column.field === 'participants_partner'"
        v-html="props.formattedRow[props.column.field]"
      />
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import { BButton, VBToggle, VBTooltip, BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import formsService from "@/services/formsService";
import { questionTypes } from "@models/questionTypes";
import {
  makeErrorToast,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  formatDateTimeFromIsoDateTime
} from "@/libs/utils";
import { userRoles, formStatus, surveyStatusSelect, formStatusDisplay } from '@/models';
import { roleAlias } from "@/@aom-core/utils/utils";

const MAX_FORM_REMINDERS = 4;

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BFormCheckbox
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      perPage: 100,
      page: 1,
      element: {},
      dir: false,
      columns: [
        {
          field: 'checked',
          width: "1em",
        },
        {
          label: "Participants",
          field: "participants",
          filterOptions: {
            enabled: true,
            placeholder: "Search Participants",
          },

          width: "10em",
        },
        {
          label: "Participant’s partner",
          field: "participants_partner",
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          width: "14em",
        },
        {
          label: "Role",
          field: "role",
          filterOptions: {
            enabled: false,
            placeholder: "Search Role",
          },
          sortable: false,
          width: "5em",

        },
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false,
          },
          width: "10em",
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: surveyStatusSelect,
          },
          width: "10em",
        },
        {
          label: "Submitted",
          field: "submitted_at",
          filterOptions: {
            enabled: false,
          },
          width: "12em"
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
      filterFields: [
        "participants",
        "role",
        "status",
      ],
      formStatus,
      formStatusDisplay,
      selected: [],
      selectAll: false,
      bulkSelection: [],
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),

    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  watch: {
    selectAll() {
      this.selectAllChange();
    },
    selected(n) {
      if(n) {
        this.selectBulkChange(n);
      }
    },
    bulkSelection(n) {
      this.$emit("selected-participants", n);
    }
  },
  created() {
    this.loadItems(true);
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (field === "participants") {
          field = "user.full_name";
        } else if (field === "role") {
          field = "form.roles_name";
        } else if (field === "action") {
          field ="reminder_count";
        } else if (field === "status") {
          field ="status_id";
        } else if (field === "submitted_at") {
          field = "submitted_at";
        } else {
          field = `questions.${field.split("_")[2]}.answer_text`;
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of this.filterFields) {
        if (params.columnFilters[col]) {
          if (col.split("_")[0] === "question") {
            const column = this.columns.find(c => c.field === col);
            const objFilter = this.getFieldName(column.typeQuestionId);
            columnFilters.push({
              field: objFilter.answer,
              value: params.columnFilters[col],
            });
            columnFilters.push({
              field: objFilter.question,
              value: column.quesId,
            });
          } else if (col === "participants") {
            columnFilters.push({
              field: 'user.full_name',
              value: params.columnFilters[col],
            });
          } else if (col === "role") {
            columnFilters.push({
              field: 'user.user_roles.role.name',
              value: params.columnFilters[col],
            });
          } else if (col === "status") {
            columnFilters.push({
              field: 'status_id',
              value: params.columnFilters[col],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems(isFirstLoading = false) {
      try {
        this.isLoading = true;
        const response = await formsService.getProgramSurveyResult(
          this.$route.params.id,
          this.$route.params.survey,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        if (isFirstLoading) {
          this.columns = [
            ...this.columns,
            ...response.data.items[0].questions.map((q, index) => {
              this.filterFields.push(`question_${index + 1}_${q.id}`);
              return {
                label: q.translations[0].question_text,
                field: `question_${index + 1}_${q.id}`,
                filterOptions: {
                  enabled: true,
                  placeholder: "Search Answer",
                },
                width: '15em',
                tooltip: q.translations[0].question_text,
                thClass: "text-truncate",
                typeQuestionId: q.type_id,
                quesId: q.id
              };
            }),
          ];
        }
        this.total = response.data.total;
        this.rows = response.data.items.map(i => ({
          ...{
            userFormId: i.id,
            participants: i.user.full_name,
            participantId: i.user.id,
            role: i.user.user_roles.filter(r => r.role_id !== userRoles.PARTICIPANT_CANDIDATE).map(r => roleAlias(r.role.id, this.defaultProgram)).join(","),
            action: {
              userId: i.user.id,
              text: `Send Reminder (${
                i?.reminder_count < MAX_FORM_REMINDERS
                  ? i?.reminder_count + "/4"
                  : "MAX"
              })`,
              isMaxFormReminders:
                i?.reminder_count == MAX_FORM_REMINDERS ? true : false,
            },
            status: i.status ? i.status.name : "",
            match_started: i.program_match ? i.program_match.started_at : null,
            submitted_at: i.submitted_at
              ? formatDateTimeFromIsoDateTime(i.submitted_at, true, 'DD-MM-YYYY, HH:mm')
              : "Not submitted",
            participants_partner: i.program_match && i.program_match.users
              ? i.program_match.users.reduce(function (filtered, option) {
                  if (option.id !== i.user_id) {
                    filtered.push(option.full_name);
                  }
                  return filtered;
                }, []).join("<br/>")
              : "", 
            vgtDisabled: i?.status?.name == 'Completed' || i?.reminder_count == MAX_FORM_REMINDERS ? true : false || i.completed_at,
            completed_at: i.completed_at
          },
          ...i.questions.reduce(
            (q, item, index) => ({
              ...q,
              [`question_${index + 1}_${item.id}`]: this.getAnswer(item),
            }),
            {}
          ),
        }));
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          this.$log.error(e);
        }
        return;
      } finally {
        this.isLoading = false;
      }
    },
    getAnswer(question) {
      if (question) {
        if (question.type_id === questionTypes.TEXT) {
          return question.answer?.answer_text;
        } else if (question.type_id === questionTypes.SCALE) {
          return question?.statements
            .map(s => s.answer?.choice.translations[0].choice_text)
            .join(" ");
        } else if (question.type_id === questionTypes.CHOICE) {
          if (question.answer?.choice.is_other) {
            return question.answer?.answer_text;
          }
          return question.answer?.choice.translations[0].choice_text;
        } else if (question.type_id === questionTypes.STATEMENT) {
          return question.answer?.choice.translations[0].choice_text;
        } else {
          return "";
        }
      }
    },
    selectBulkChange(n) {
      if(n.length === 0) {
        this.bulkSelection = [];
        this.selectAll = false;
        return;
      };
      const rowsCanSelect = this.rows.filter(r => !r.vgtDisabled);
      const rows = this.rows.filter(r => !r.vgtDisabled && n.includes(r?.participantId));

      if(rowsCanSelect.length === rows.length) {
        this.selectAll = true;
      }
      this.bulkSelection = rows;
    },
    selectAllChange() {
      if(!this.selectAll) {
        this.selected = [];
        return;
      }
      const rows = this.rows.filter(r => !r.vgtDisabled);
      rows.forEach(r => {
        if(!this.selected.includes(r.participantId) || this.selected.length === 0) {
          this.selected.push(r.participantId);
        }
      });
      this.bulkSelection = rows;
    },
    async sendReminder(userId,  userFormId) {
      this.$bvModal
        .msgBoxConfirm(
          "Do you want to send this participant an email reminding them to complete this survey?",
          {
            title: "Send Reminder?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Send Reminder",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
                await formsService.sendSurveyReminders(
                this.$route.params.id,
                userId,
                this.$route.params.survey,
                userFormId
              );
              await this.loadItems(true);
            } catch (e) {
              this.$log.error(e);
              this.$toast(
                makeErrorToast("Something went wrong. Can't send reminder")
              );
            }
          }
        });
    },
    getFieldName(typeId) {
      switch (typeId) {
      case questionTypes.TEXT:
        return {
          answer: "answers.answer_text",
          question: "answers.question_id"
        };
      case questionTypes.SCALE:
        return {
          answer: "answers.choice.translations.choice_text",
          question: "answers.question.parent_question.id"
        };
      case questionTypes.CHOICE:
        return {
          answer: "answers.choice.translations.choice_text",
          question: "answers.question_id"
        };
      default:
        return "";
      }
    },
    getStatus(params) {
      const { status, openAt, isSubmitted } = params;
      
      if (status && isSubmitted) {
        return 'Submitted';
      }
      if (status && !openAt) {
        return 'Not Started';
      }
      if (status && openAt && !isSubmitted) {
        return 'Not submitted';
      }
    }
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
